export class Device
{
    id
    name
    address
    city
    county
    province
    postcode
    tel
    warehouse
    payments
    admins
    permissions
    branch

    get fullAddress() {
        return this.address + ' ' + this.city + ' ' + this.county + ' ' + this.province + ' ' + this.postcode
    }

    get warehouseName() {
        return !!this.warehouse ? this.warehouse.name : ''
    }

    get warehouseId() {
        return !!this.warehouse ? this.warehouse.id : null
    }    

    constructor({
        id,
        name,
        address,
        city,
        county,
        province,
        postcode,
        tel,
        warehouse,
        payments,
        admins,
        branch
    }) {
        this.id = id
        this.name = name
        this.address = address
        this.city = city
        this.county = county
        this.province = province
        this.postcode = postcode
        this.tel = tel
        this.branch = branch

        this.warehouse = warehouse

        if (payments)
            this.payments = [{id: 0, name: 'เงินสด', disabled: true}, ...payments]
        else
            this.payments = [{id: 0, name: 'เงินสด', disabled: true}]

        if (!admins) {
            this.admins = []
            this.permissions = []
        } else {
            this.admins = admins
            this.permissions = admins.map(item => item.pivot.permission)
        }        
    }

    serialize() {
        return {
            name: this.name,
            address: this.address,
            city: this.city,
            county: this.county,
            province: this.province,
            postcode: this.postcode,
            tel: this.tel,
            branch: this.branch,
            warehouse_id: this.warehouseId,
            payment_ids: this.payments.map(item => item.id).filter(item => item > 0),
            admin_ids: this.admins.map(item => item.id),
            permissions: this.permissions
        }
    }

    getPermissionByAdminId(adminId) {
        const admin = this.admins.find(item => item.id == adminId)

        if (!admin) return 0
        

        return admin.pivot.permission
    }
}