<template>
    <div>
        <h2>POS > เครื่อง POS</h2>
        
        <v-card>
          <v-list dense>
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
            >
              <v-subheader>เลือกเครื่อง POS ที่ต้องการ</v-subheader>
              <v-list-item 
              two-line 
              v-for="(device, index) in devices"
              :key="device.id"
              @click="$router.push({ name: 'posorder', params: { device_id: device.id } })"
              >
                  <v-list-item-content>
                      <v-list-item-title>{{ device.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ device.warehouseName }}</v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>        
    </div>
</template>
<script>
import { Device } from '@/classes/pos/device'
import { asyncGet } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import { mdiClose } from '@mdi/js'
export default {
  data() {
    return {
      devices: [],
      selectedItem: null,
      orderListDialog: false,
      icons: {
        mdiClose,
      },
    }
  },
  async created() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    try {
      const response = await asyncGet('/pos/devices')

      this.devices = response.map(item => new Device(item))
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
}
</script>